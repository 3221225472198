.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
}

.title {
  font-size: 45px;
  margin-bottom: 40px;
}

.mainContainerIcons {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.containerIcons {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.containerIconsAndText {
  min-width: 300px;
  border: 2px solid #c79f47;
  border-radius: 20px;
 
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  transition: all 0.3s ease;
}

.containerIconsAndText:hover {
  transform: translateY(-10px);
}

.iconAndTitle {
  display: flex;
  width: 100%;

  align-items: center;
  border-bottom: 1px solid #c79f47;
  padding-bottom: 10px;
}

.cardTitle {
  font-size: 20px;
  text-align: center;
  width: 100%;
  font-weight: 500;
}

.explanationText {
  padding-left: 7px;
  display: flex;
  gap: 10px;
  margin-top: 15px;
  width: 300px;
  padding-bottom: 10px;
}

@media (max-width: 450px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
  }

  .title {
    font-size: 35px;
    text-align: center;
    margin-bottom: 40px;
  }

  .mainContainerIcons {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .containerIcons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
  }

  .containerIconsAndText {
    min-width: 300px;
    border: 2px solid #c79f47;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.13) 1px 1px 7px 0px,
      rgba(0, 0, 0, 0.05) 0px 0px 2px 0px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    transition: all 0.3s ease;
  }

  .containerIconsAndText:hover {
    transform: translateY(-10px);
  }

  .iconAndTitle {
    display: flex;
    width: 100%;
  
    align-items: center;
    border-bottom: 1px solid #c79f47;
    padding-bottom: 10px;
  }

  .cardTitle {

    font-size: 20px;
    text-align: center;
    width: 100%;
    font-weight: 500;
  }

  .explanationText {
    padding-left: 7px;
    display: flex;
    gap: 10px;
    margin-top: 15px;
    width: 300px;
    padding-bottom: 10px;
  }
}
