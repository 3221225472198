.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  background-image: linear-gradient(135deg,#C79F47,#D4B759);
  color: white;
  width: 100%;
  height: 21.5vw;
  overflow: hidden;
}

.itensContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  bottom: 15%;
}

.cropImage {
  width: 500px;
  height: 380px;
}

.logo {
  object-fit: cover;
  width: 500px;
  height: 500px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}


.iconItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

 

 
}

.iconItem span {
  margin-left: 10px;
  color: white;
  font-size: 18px;
}


@media (max-width: 450px) {
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  background-image: linear-gradient(135deg,#C79F47,#D4B759);
  color: white;
  width: 100%;
  height: 50vh;
}

.itensContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  bottom: 15%;
}

.cropImage {
  width: 414px;
  height: 380px;
}

.logo {
  object-fit: cover;
  width: 414px;
  height: 500px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}


.iconItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

 

 
}

.iconItem span {
  margin-left: 10px;
  color: white;
  font-size: 18px;
}
}