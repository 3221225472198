.container {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-image: url("./imgs/herotest.jpg");
}

.imageOverlay {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.764);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.itensContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  bottom: 8%;
}

.cropImage {
  width: 500px;
  height: 380px;
}

.logo {
  object-fit: cover;
  width: 500px;
  height: 500px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.title {
  margin-top: 0px;
  padding-top: 0px;
  margin-bottom: 15px;
  font-size: 60px;
}

.paragraphText {
  width: 600px;
  margin-bottom: 20px;
  font-size: 19px;
  text-align: center;
}

.speaknow {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 350px;
  height: 60px;
  border-radius: 8px;
  background-color: #009f16;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  padding-left: 21px;
  border: none;
  cursor: pointer;
  margin-top: 15px;
  background-size: 400% 200%;
  animation: gradientAnimation 5s infinite;
  transition: all 0.3s ease;
  
}

.speaknow:hover {
  transform: translateY(-4px);
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 450px) {
  .container {
    width: 100%;
    height: 100vh;
    background-position: right 348% bottom 35%;
    background-size: cover;
    background-image: url("./imgs/herotest.jpg");
  }

  .imageOverlay {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.794);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .itensContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
    bottom: 8%;
  }

  .cropImage {
    width: 414px;
    height: 380px;
  }

  .logo {
    object-fit: cover;
    width: 414px;
    height: 500px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .title {
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 15px;
    font-size: 35px;
    text-align: center;
  }

  .paragraphText {
    width: 600px;
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
    width: 350px;
  }

  .speaknow {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 300px;
    height: 60px;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    font-size: 17px;
    padding-left: 21px;
    border: none;
    cursor: pointer;
    margin-top: 15px;
    background-color: #009f16;

    background-size: 400% 200%;
    animation: gradientAnimation 5s infinite;
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}
