.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  padding-top: 90px;
}

.matheus {
  border-radius: 0px 80px 0px 80px;
  width: 435px;
  height: 545px;
}

.informationsContainer {
  display: flex;
  flex-direction: column;
}

.aboveTitle {
  margin-bottom: 15px;
 
}

.title {
  font-size: 45px;
  margin-bottom: 17px;
}

.paragaph1 {
  width: 550px;
  margin-bottom: 15px;
}

.paragaph2 {
  width: 550px;
}

.speaknow {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 350px;
  height: 60px;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  padding-left: 21px;
  border: none;
  cursor: pointer;
  margin-top: 28px;
  background-color: #009f16;

  background-size: 400% 200%;
  animation: gradientAnimation 5s infinite;
  transition: all 0.3s ease;
}

.speaknow:hover {
  transform: translateY(-4px);
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 450px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-top: 90px;
  }

  .matheus {
    border-radius: 0px 80px 0px 80px;
    width: 95%;
  }

  .informationsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .aboveTitle {
    margin-bottom: 15px;
  }

  .title {
    font-size: 35px;
    text-align: center;
    margin-bottom: 17px;
  }

  .paragaph1 {
    width: auto;
    margin-bottom: 15px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }

  .paragaph2 {
    width: auto;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }

  .speaknow {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 300px;
    height: 60px;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    font-size: 17px;
    padding-left: 21px;
    border: none;
    cursor: pointer;
    margin-top: 28px;
    background-color: #009f16;

    background-size: 400% 200%;
    animation: gradientAnimation 5s infinite;
  }

  .speaknow:hover {
    transform: translateY(-4px);
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}
